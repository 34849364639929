<template>
  <div v-if="ListDetail.length > 0">
    <div>
      <div class="font-weight-black mb-2">{{ bullet_title }}</div>
    </div>

    <div v-for="item in ListDetail" :key="item.id" class="line">
      <ul class="my-2">
        <li class="font-weight-black">
          {{ $i18n.locale === 'th_TH' ? item.title_th : item.title_en }}
        </li>
        <div>
          {{ $i18n.locale === 'th_TH' ? item.body_th : item.body_en }}
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ItemList',
  props: {
    bullet_title: {
      type: String,
      default: '',
    },
    ListDetail: {
      type: [Array, Object],
      default: () => [],
    },
  },
}
</script>

<style scoped>
.line {
  border-top: 1px solid #707070;
  border-top-style: dashed;
}

ul {
  list-style: none;
}

li::before {
  content: '●';
  color: #ce0000;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
</style>
