<template>
  <div id="moomallContentDetail">
    <Navbar
      @loadData="loadData"
      :isShare="isShare"
      :isVisibleEp="isVisibleEp"
      :isVisibleJp="isVisibleJp"
      :isVisibleSp="isVisibleSp"
    >
      <span class="text-title">
        {{
          $i18n.locale === 'th_TH'
            ? ContentDetail.title_th
            : ContentDetail.title_en
        }}</span
      >
    </Navbar>
    <v-main>
      <BasePdf
        v-if="ContentDetail.content_image_id"
        :pathImage="ContentDetail.content_image_id"
      />
      <div v-else>
        <BaseVimeo
          v-if="ContentDetail.video_url && checkVideo(ContentDetail.video_url)"
          :key="componentKey"
          cssVideo="video"
          :pathVideo="ContentDetail.video_url"
        ></BaseVimeo>
        <BaseVideo
          v-if="ContentDetail.video_url && !checkVideo(ContentDetail.video_url)"
          cssVideo="video"
          :pathVideo="ContentDetail.video_url"
        />
        <BaseImage
          v-if="!ContentDetail.video_url"
          :pathImage="ContentDetail.image_id"
        />
        <v-card tile elevation="0" class="mb-2 pa-4">
          <BaseTextDetail
            :key="componentKey"
            css="black--text text-indent body-text mb-7"
            :text="
              $i18n.locale === 'th_TH'
                ? ContentDetail.body_th
                : ContentDetail.body_en
            "
          />
          <ItemList
            :key="componentKey"
            :ListDetail="ListDetail"
            :bullet_title="
              $i18n.locale === 'th_TH'
                ? ContentDetail.bullet_title_th
                : ContentDetail.bullet_title_en
            "
          />
        </v-card>
        <BaseLabelCardList
          :key="componentKey"
          :label="
            $i18n.locale === 'th_TH'
              ? ContentDetail.title_th
              : ContentDetail.title_en
          "
          :numberCourse="CardList.length"
          :isContant="isContant"
          :isContantSub="isContantSub"
          :sellerSku="checkSellerSKU(ContentDetail.seller_sku)"
          @activeContantMobile="activeContantMobile"
        />
        <BaseCardList
          v-if="ContentDetail.video_url == ''"
          :itemList="CardList"
          @loadData="loadData"
          :isVideo="true"
        />

        <BaseCardVideo
          v-else
          @activeContant="SendContentDetail"
          :activeId="ContentDetail.id"
          :itemList="CardList"
          @loadData="loadData"
          :isVideo="true"
          :isContant="isContant"
          :isContantSub="isContantSub"
          :sellerSku="checkSellerSKU(ContentDetail.seller_sku)"
        />
      </div>
      <scroll-loader
        :loader-method="loading"
        :loader-disable="disable"
        loader-color="#D60000"
      >
      </scroll-loader>
    </v-main>
  </div>
</template>

<script>
import ItemList from '@/components/Main/Content-Moomall/ItemList'
import ConntentsServices from '@/services/ConntentsServices'
import CourseServices from '@/services/CourseServices'
import { config } from '@/config'
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'ContentDetail',
  components: { ItemList },
  metaInfo() {
    return {
      title: 'Academy',
      titleTemplate: '%s | Moomall Academy',
      htmlAttrs: {
        lang: 'th',
      },
      meta: [
        {
          property: 'og:description',
          vmid: 'og:description',
          content: this.ContentDetail.bullet_title_th,
        },
        {
          property: 'og:title',
          vmid: 'og:title',
          content: this.ContentDetail.title_th,
        },
        {
          property: 'og:image',
          vmid: 'og:image',
          content:
            this.$appConfig.serveConfig.SERVICE_URL_IMAGE +
            this.ContentDetail.image_id,
        },
      ],
    }
  },
  data() {
    return {
      ContentDetail: {},
      ListDetail: [],
      CardList: [],
      disable: false,
      isShare: true,
      page: 1,
      pageSize: 10,
      totalItems: 0,
      isVisibleEp: false,
      isVisibleJp: false,
      isVisibleSp: false,
      isContant: false,
      isContantSub: false,
      componentKey: 0,
      contentList: [],
    }
  },
  props: {
    id: {
      type: [Number, String],
      default: null,
    },
  },
  async created() {
    await this.loadData(this.id)
    this.apiOrderDetailList() // ดึงข้อมูลสินค้าวิดีโอที่ซื้อไปแล้ว
    this.apiOrderCourselList() // ดึงข้อมูลเบอร์มือถือมาไว้เช็คสินค้าวิดีโอที่ซื้อไปแล้ว
  },
  methods: {
    activeContantMobile() {
      this.apiOrderCourselList()
    },
    checkSellerSKU(sellerSku) {
      if (
        sellerSku == null ||
        sellerSku == undefined ||
        sellerSku == '' ||
        sellerSku == 'null' ||
        sellerSku == 'undefined'
      ) {
        return false
      } else {
        return true
      }
    },
    ...mapGetters({ MallMemberTokenKey: 'PacerStore/MallMemberTokenKey' }),
    ...mapGetters({ Mobile: 'PacerStore/Mobile' }),
    checkVideo(link) {
      const checkLinkManage = link.split('https://www.youtube.com/')
      if (checkLinkManage.length > 1) {
        return false
      } else {
        return true
      }
    },
    async apiOrderDetailList() {
      const apiServicesCH = await axios.create({
        baseURL: config.serveConfig.SERVICE_URL_CH,
        withCredentials: false,
        headers: {
          Accept: '*/*',
          'Content-Type': ' application/x-www-form-urlencoded',
          MALL_MEMBER_TOKEN_KEY: `${this.MallMemberTokenKey()}`,
        },
        mode: 'cors',
      })

      try {
        await apiServicesCH
          .get()
          .then(res => {
            if (res.data.result != null) {
              this.contentList = res.data.result
            }
          })
          .catch(err => {
            console.error(err.message)
          })
      } catch (error) {
        console.log(error)
      }
    },
    apiOrderCourselList() {
      CourseServices.getCourseLists().then(async resp => {
        try {
          this.isContantSub = await this.checkContentListByPhone(resp.data)
          console.log('this.isContantSub', this.isContantSub)
        } catch (error) {
          console.log('CourseLists API Error', error)
          this.isContantSub = false
        }
      })
    },
    checkContentList(sellerSKU) {
      return this.contentList.some(list => {
        return list.productSku == sellerSKU
      })
    },
    checkContentListByPhone(phoneList) {
      console.log('Mobile===>', this.Mobile())
      try {
        return phoneList.some(list => {
          return list.phone == this.Mobile()
        })
      } catch (error) {
        console.log(error)
        return false
      }
    },
    SendContentDetail(value) {
      this.ContentDetail = value
      this.componentKey++
    },
    loadData(ID) {
      this.ContentDetail = {}
      this.ListDetail = []
      this.CardList = []

      window.scrollTo(0, 0)
      ConntentsServices.getContents(ID)
        .then(resp => {
          if (resp.status === 200) {
            this.ContentDetail = resp.data || {}
            this.isVisibleEp = resp.data.is_visible_ep || false
            this.isVisibleJp = resp.data.is_visible_jp || false
            this.isVisibleSp = resp.data.is_visible_sp || false
            if (this.contentList.length > 0) {
              this.isContant =
                this.checkContentList(resp.data.seller_sku) || false
            }

            ConntentsServices.getContent_Lists(this.ContentDetail.id)
              .then(respList => {
                if (resp.status === 200) {
                  this.ListDetail = respList.data || []
                }
              })
              .catch(err => {
                console.error(err.message)
              })
            this.page = 1
            this.totalItems = 0
            this.disable = false
            ConntentsServices.getContentsByParentId({
              id: this.ContentDetail.id,
              page: this.page,
              limit: this.pageSize,
            })
              .then(respCardList => {
                if (resp.status === 200) {
                  this.page++
                  this.CardList = respCardList.data.items || []
                  this.totalItems = respCardList.data.meta.totalItems
                  if (this.CardList.length === this.totalItems) {
                    this.disable = true
                    if (this.ContentDetail.video_url != '') {
                      this.CardList.splice(0, 0, this.ContentDetail)
                    }
                  }
                }
              })
              .catch(err => {
                console.error(err.message)
              })
          }
        })
        .catch(err => {
          this.$swal
            .fire({
              icon: 'error',
              title: `${err.message}`,
              showDenyButton: false,
            })
            .then(result => {
              if (result.isConfirmed) {
                this.$router.push({ name: 'News' })
              }
            })
        })
    },
    loading() {
      if (this.totalItems > 0) {
        ConntentsServices.getContentsByParentId({
          id: this.ContentDetail.id,
          page: this.page,
          limit: this.pageSize,
        })
          .then(res => {
            if (res.status === 200) {
              this.page++
              this.CardList.push(...res.data.items)
              this.totalItems = res.data.meta.totalItems

              // Stop scroll loading...
              if (this.CardList.length === this.totalItems) {
                this.disable = true
              }
            }
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    async forceRerender() {
      this.componentKey += 1
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.loadData(to.params.id)
    next()
  },
  watch: {
    ContentDetail() {
      this.forceRerender()
    },
  },
}
</script>

<style scoped>
.text-title {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
</style>
